import { useState,useEffect } from "react";

const LinkPreview = ({ url }) => {
    const [previewData, setPreviewData] = useState(null);
    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          // Prepend the AllOrigins proxy URL to your target URL
          const proxyUrl = `https://thingproxy.freeboard.io/fetch/${encodeURIComponent(url)}`;
          const response = await fetch(proxyUrl);
          // AllOrigins returns JSON with a 'contents' property that contains the HTML
          const data = await response.text();
          
          const parser = new DOMParser();
          const doc = parser.parseFromString(data, 'text/html');
  
          const title = 
            doc.querySelector('meta[property="og:title"]')?.getAttribute('content') ||
            doc.querySelector('title')?.textContent ||
            '';
          const description =
            doc.querySelector('meta[property="og:description"]')?.getAttribute('content') ||
            doc.querySelector('meta[name="description"]')?.getAttribute('content') ||
            '';
          const image =
            doc.querySelector('meta[property="og:image"]')?.getAttribute('content') || '';
          setPreviewData({ title, description, image });
          setLoading(false);
        } catch (error) {
          console.error("Error fetching preview:", error);
          setLoading(false);
        }
      };
  
      fetchData();
    }, [url]);
  
    if (loading) return <p></p>;
    if (!previewData) return <p>No preview available.</p>;
  
    return (
      <div className="link-preview" style={{
        backgroundColor: 'white', 
        padding: '1rem', 
        display: 'flex', 
        flexDirection: 'column',
        gap:'1rem',
        borderLeft: '6px solid #4dbafd',
        marginTop:'1rem',}}>
        <h3>{previewData.title}</h3>
        <p>{previewData.description}</p>
        {previewData.image && <img src={previewData.image} alt="Link Preview" />}
      </div>
    );
  };


  export default LinkPreview;